.base-blue-button {
  @apply bg-blue-600 hover:bg-blue-700 text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800;
}
.base-gray-button {
  @apply text-gray-900 bg-gray-300 border border-gray-300 focus:outline-none hover:bg-gray-100  font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700;
}
.base-green-button {
  @apply focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800;
}
.base-red-button {
  @apply focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900;
}
.base-yellow-button {
  @apply focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900;
}

.base-p {
  @apply dark:text-white text-gray-900;
}

.base-card {
  @apply bg-white dark:bg-gray-800;
}

.base-border {
  @apply border border-gray-400 dark:border-gray-500 rounded-lg;
}

.basic-cell {
  @apply px-6 py-4;
}

.base-heading {
  @apply text-xl font-bold dark:text-white;
}
